//
// _helper.scss
//
.fw-medium {
    font-weight: $fw-medium;
}

.section {
    padding: 15px 0;
    position: relative;
    @media (max-width: 768px) {
        padding: 60px 0;
    }
}
.section-two {
    padding: 60px 0;
    position: relative;
}
.bg-overlay {
    background-color: $overlay;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%
}

.bg-overlay-white {
    background-color: $bg-overlay-white;
}

.bg-gradient-primary {
    background: $bg-gradient-primary;
}

.bg-linear-gradient {
    background: $linear-gradient;
}

.bg-linear-gradient-2 {
    background: $linear-gradient-2;
}

.bg-linear-gradient-3 {
    background: $linear-gradient-3;
}

.bg-gradient-overlay {
    background: $gradient-overlay;
}

.bg-gradient-overlay-2 {
    background: $gradient-overlay-2;
}

.bg-primary-gradient-overlay {
    background: $primary-gradient-overlay;
}

.bg-gradient-white-overlay {
    background: $gradient-white-overlay;
}

//Title
.title-heading {
    line-height: 26px;
    .heading {
        font-size: 45px !important;
        letter-spacing: 1px;
        @media (max-width: 768px) {
            font-size: 35px !important;
        }
    }
    .para-desc {
        font-size: 18px;
    }
}
.section-title {
    // position: relative;
    .title {
        letter-spacing: 0.5px;
        font-size: 30px !important;

        @media (max-width: 768px) {
            font-size: 24px !important;
        }
    }
}
.text-shadow-title {
    text-shadow: 2px 0 0 $white,
                -2px 0 0 $white,
                0 4px 0 rgba($white, 0.4),
                0 -2px 0 $white,
                1px 1px $white,
                -1px -1px 0 $white,
                1px -1px 0 $white,
                -1px 1px 0 $white;
}
.para-desc {
    max-width: 900px;
}
.mt-100 {
    margin-top: 100px;
}

// Text Slider
.text-slider {
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    transform: translate3d(0, 0, 0);
    > ul {
        white-space: nowrap;
    }
    .move-text {
        animation: moveSlideshow 12s linear infinite;
    }
}
@keyframes moveSlideshow {
    100% {
        transform: translateX(-45%);
    }
}

//Mover
.mover {
    animation: mover 1s infinite alternate;
}
@keyframes mover {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(5px);
    }
}

//Feature post placeholder
.feature-posts-placeholder {
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    height: 66.6%;

    @media (max-width: 425px) {
        height: 80%;
    }
}


//Spinner Class
.spin-anything {
    animation: spinner-animation 5s linear infinite
}

@keyframes spinner-animation {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}

//SEO Home
.seo-hero-widget {
    position: relative;
    transition: all 0.5s ease;
    &:hover {
        transform: scale(1.05);
        z-index: 9;
    }
}

.home-wrapper:before {
    content: "";
    position: absolute;
    bottom: 40rem;
    right: 0rem;
    width: 90rem;
    height: 35rem;
    transform: rotate(78.75deg);
    background: linear-gradient(90deg, rgba($primary, 0.3), transparent);
    z-index: 1;

    @media (min-width: 769px) and (max-width: 1024px) {
        right: -20rem;
    }

    @media (min-width: 426px) and (max-width: 768px) {
        right: -15rem;
        width: 70rem;
        height: 25rem;
    }

    @media (max-width: 425px) {
        right: -25rem;
    }
}

//Cookies
.cookie-popup {
    position: fixed;
    bottom: 12px;
    left: 0;
    right: 0;
    margin: 0 auto;
    max-width: 512px;
    z-index: 5;
    .cookie-popup-actions {
        button {
            border: none;
            background: none;
            padding: 0;
            cursor: pointer;
            font-weight: 600;
            position: absolute;
            top: 5px;
            right: 5px;
        }
    }
    @media (max-width: 425px) {
        left: 12px;
        right: 12px;
    }
}

.cookie-popup-not-accepted {
    display: block;
    animation: cookie-popup-in .5s ease forwards;
}

.cookie-popup-accepted {
    display: none !important;
}

@keyframes cookie-popup-in {
    from {
        bottom: -6.25rem;
    }

    to {
        bottom: 1.25rem;
    }
}

//Clip Text
.clip-text {
    font-size: 190px;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    &.clip-text-image {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    @media (max-width: 1024px) {
        font-size: 180px;
    }
    @media (max-width: 768px) {
        font-size: 135px;
    }
    @media (max-width: 425px) {
        font-size: 50px;
    }
}

@media (min-width: 768px) {
    .margin-top-100 {
        margin-top: 100px;
    }
}
@media (max-width: 768px) {
    .mt-60 {
        margin-top: 60px;
    }
}

.background-effect {
    .circles {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        overflow: hidden;
        li {
            position: absolute;
            display: block;
            animation: animate 25s linear infinite;
            bottom: -150px;
            background: rgba($primary, 0.15);
            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5),
            &:nth-child(6),
            &:nth-child(7),
            &:nth-child(8),
            &:nth-child(9),
            &:nth-child(10) {
                width: 24px;
                height: 24px;
            }
            &:nth-child(1) {
                left: 25%;
                animation-delay: 0s;
            }

            &:nth-child(2) {
                left: 10%;
                animation-delay: 2s;
                animation-duration: 12s;
            }

            &:nth-child(3) {
                left: 70%;
                animation-delay: 4s;
            }

            &:nth-child(4) {
                left: 40%;
                animation-delay: 0s;
                animation-duration: 18s;
            }

            &:nth-child(5) {
                left: 65%;
                animation-delay: 0s;
            }

            &:nth-child(6) {
                left: 75%;
                animation-delay: 3s;
            }

            &:nth-child(7) {
                left: 35%;
                animation-delay: 7s;
            }

            &:nth-child(8) {
                left: 50%;
                animation-delay: 15s;
                animation-duration: 45s;
            }

            &:nth-child(9) {
                left: 20%;
                animation-delay: 2s;
                animation-duration: 35s;
            }

            &:nth-child(10) {
                left: 85%;
                animation-delay: 0s;
                animation-duration: 11s;
            }
        }
    }
}

@keyframes animate {
    0% {
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 10px;
    }

    100% {
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
    }
}


//Feather Icon
.fea {
    stroke-width: 1.8;
    &.icon-sm {
        height: 16px;
        width: 16px;
    }
    &.icon-ex-md {
        height: 20px;
        min-width: 20px;
    }
    &.icon-m-md {
        height: 28px;
        width: 28px;
    }
    &.icon-md {
        height: 35px;
        width: 35px;
    }
    &.icon-lg {
        height: 42px;
        width: 42px;
    }
    &.icon-ex-lg {
        height: 52px;
        width: 52px;
    }
}

[class^=uil-],
[class*=" uil-"] {
    &:before {
        margin: 0;
    }
}

.uim-svg {
    vertical-align: 0 !important;
}

// Rounded
.rounded {
    border-radius: 6px !important;
}
.rounded-top {
    border-top-left-radius: 6px !important;
    border-top-right-radius: 6px !important;
}
.rounded-left {
    border-top-left-radius: 6px !important;
    border-bottom-left-radius: 6px !important;
}
.rounded-bottom {
    border-bottom-left-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
}
.rounded-right {
    border-top-right-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
}

.rounded-md {
    border-radius: 10px !important;
}
.rounded-lg {
    border-radius: 30px !important;
}

//Border
@each $name,
$value in $theme-colors {
    .border-#{$name} {
        border-color: #{$value} !important;
    }
}

// Opacity
.opacity-05 {
    opacity: 0.05;
}

.opacity-075 {
    opacity: 0.075;
}

.opacity-1 {
    opacity: 0.1;
}

.opacity-2 {
    opacity: 0.2;
}

.opacity-3 {
    opacity: 0.3;
}

.opacity-4 {
    opacity: 0.4;
}

.opacity-5 {
    opacity: 0.5;
}

.opacity-6 {
    opacity: 0.6;
}

.opacity-7 {
    opacity: 0.7;
}

.opacity-8 {
    opacity: 0.8;
}

.opacity-9 {
    opacity: 0.9;
}

//Z Index css
.z-index-1 {
    z-index: 1;
}
.z-index-0 {
    z-index: 0;
}
.z-index-m-1 {
    z-index: -1;
}

//Small
.small, small {
    font-size: 90%;
}


//Social icons
.social-icon {
    li {
        a {
            color: $dark;
            border: 1px solid $dark;
            height: 32px;
            width: 32px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            transition: all 0.4s ease;
            overflow: hidden;
            position: relative;
            .fea-social {
                stroke-width: 2;
            }
            &:hover {
                background-color: $primary;
                border-color: $primary !important;
                color: $foot-social-color-white !important;
                .fea-social {
                    fill: $primary;
                }
            }
        }
    }
    &.social {
        li {
            a {
                color: $foot-social-color;
                border-color: $social-border-color;
            }
        }
    }

    &.foot-social-icon {
        li {
            a {
                color: $foot-social-color;
                border-color: lighten($footer, 5%);
            }
        }
    }
}
//back to top
.back-to-top {
    position: fixed;
    z-index: 99;
    bottom: 30px;
    right: 30px;
    display: none;
    transition: all 0.5s ease;
    height: 36px;
    width: 36px;
    line-height: 33px;
    border-radius: 6px;
    text-align: center;
    background: $primary;
    color: $back-to-top-color !important;
    .icons {
        transition: all 0.5s ease;
    }
    &:hover {
        transform: rotate(45deg);
        .icons {
            transform: rotate(-45deg) !important;
        }
    }
}

//back-to-home
.back-to-home {
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 1;
}

//Sticky Sidebar
.sticky-bar {
    position: sticky;
    top: 80px;
}

//position-middle-bottom
.position-middle-bottom {
    position: absolute;
    bottom: 15px;
    left: 12px;
    right: 12px;
    text-align: center;
}

//Text-color-white
.text-color-white {
    color: $text-color-white !important;
}

.d-flex {
    .flex-1 {
        flex: 1;
    }
}
