/*
Template Name: Landrick - Saas & Software Bootstrap 5 Landing & Admin Dashboard Template
Author: Shreethemes
E-mail: support@shreethemes.in
Created: August 2019
Version: 4.2.0
File: Custom Bootstrap Css File
*/


//Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "variables";
@import "./node_modules/bootstrap/scss/bootstrap";

@import "custom/components/reboot";
@import "custom/components/alerts";
@import "custom/components/backgrounds";
@import "custom/components/text";
@import "custom/components/badge";
@import "custom/components/buttons";
@import "custom/components/breadcrumb";
@import "custom/components/blockquote";
@import "custom/components/carousel";
@import "custom/components/card";
@import "custom/components/dropdown";
@import "custom/components/nav";
@import "custom/components/table";
@import "custom/components/pagination";
@import "custom/components/progress";
@import "custom/components/shadow";
@import "custom/components/offcanvas-modal";